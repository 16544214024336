import styled from 'styled-components';
import {
  CARD_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import {
  TERMS_URL,
  USE_POLICY_URL,
  PRIVACY_POLICY_URL,
} from '../../../pathNames';

const Footer = () => {
  const partnerSiteUrl = 'https://uncensoredai.io';
  return (
    <FooterContainer>
      <ContentContainer>
        <LinksContainer>
          <Link href={TERMS_URL} target="_blank">
            Terms of use
          </Link>
          <Link href={USE_POLICY_URL} target="_blank">
            Use Policy
          </Link>
          <Link href={PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </Link>
          <Link href="mailto:hello@joi.bot">Contact</Link>
          <Link href={partnerSiteUrl} target="_blank">
            AI sister site
          </Link>
        </LinksContainer>
        <FooterText>
          None of the people are real, and they do not mean what they say. We do
          not bear any responsibility for the conversations.
          <br />
          All rights reserved / 2024 / joi.bot
        </FooterText>
      </ContentContainer>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 92vw;

  background-color: ${CARD_BACKGROUND_COLOR};
  color: ${PRIMARY_TEXT_COLOR};

  margin-top: 80px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const FooterText = styled.div`
  margin-top: 16px;
  font-size: 14px;
  max-width: 75vw;
`;

/*
<ParterSiteLink href="https://onlyfakes.app/" target="_blank">
  <span>Visit partner site</span>
</ParterSiteLink>
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ParterSiteLink = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopMediaQuery} {
    flex-direction: row;
    gap: 16px;
  }
`;

const Link = styled.a`
  color: ${PRIMARY_TEXT_COLOR};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
