import React, { useEffect, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { LIGHTER_BG_FOR_TOAST, PRIMARY_TEXT_COLOR } from '../../constants';
import { fadeIn } from '../../common/animations';
import ImageStorage from '../../common/storages/imageStorage';
import TopBar from './MainAppCommon/TopBar/TopBar';
import AcceptTermsModal from '../../modals/AcceptTermsModal';
import { getIsJoiBot, scrollToTopSmoothly } from '../../helpers';
import AuthModal from '../../modals/AuthModal/AuthModal';
import UserTokenStorage from '../../common/storages/userTokenStorage';
import { useModalsContext } from '../../context/ModalsContextProvider';
import SettingsModal from '../../modals/ChatModals/SettingsModal';
import ShowImagesModal from '../../modals/ChatModals/ShowImagesModal';
import ChangeAppearanceModal from '../../modals/ChatModals/ChangeAppearanceModal';
import { useAuthContext } from '../../context/AuthContextProvider';
import SubscriptionModal from '../../modals/SubscriptionModal/SubscriptionModal';
import WelcomeModal from '../../modals/WelcomeModal';

const MainApp: React.FC = () => {
  const [showAcceptTermsModal, setShowAcceptTermsModal] = useState(false);
  const { showAuthModal, setShowAuthModal } = useAuthContext();
  const {
    showAppearanceModal,
    setShowAppearanceModal,
    showSettingsModal,
    setShowSettingsModal,
    showImagesModal,
    setShowImagesModal,

    showBuySubscriptionModal,
    setShowBuySubscriptionModal,
  } = useModalsContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const imageStorage = new ImageStorage();
  const tokenStorage = new UserTokenStorage();

  useEffect(() => {
    const token = tokenStorage.getToken();
    if (token) {
    }
  }, []);

  const isJoi = getIsJoiBot();
  useEffect(() => {
    if (isJoi) {
      window.location.href = 'https://SextingAI.net/app';
    }
  }, []);

  //useGetUser();

  useEffect(() => {
    //const images = imageStorage.getImages();
    scrollToTopSmoothly();
  }, []);

  useEffect(() => {
    const hasAcceptedTerms = true;

    if (!hasAcceptedTerms) {
      setShowAcceptTermsModal(true);
    }
  }, []);

  const [heightWhenOpeningMounting, setHeightWhenOpeningMounting] = useState(0);
  useEffect(() => {
    if (heightWhenOpeningMounting !== 0) return;

    const height = window.innerHeight;
    setHeightWhenOpeningMounting(height);
  }, []);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    const lastWelcomeTime = localStorage.getItem('lastWelcomeTime');
    const currentTime = new Date().getTime();
    const sixHoursInMs = 6 * 60 * 60 * 1000;

    if (!lastWelcomeTime || currentTime - parseInt(lastWelcomeTime) > sixHoursInMs) {
      setShowWelcomeModal(true);
      localStorage.setItem('lastWelcomeTime', currentTime.toString());
    }
  }, []);

  return (
    <MainAppContainer height={heightWhenOpeningMounting}>
      <StyledToastContainer
        position="bottom-center"
        hideProgressBar
        closeButton={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        draggablePercent={50}
        autoClose={TIME_TO_CLOSE_TOAST}
      />

      <AcceptTermsModal
        showModal={showAcceptTermsModal}
        setShowModal={setShowAcceptTermsModal}
      />

      <AuthModal showModal={showAuthModal} setShowModal={setShowAuthModal} />
      <ShowImagesModal show={showImagesModal} setShow={setShowImagesModal} />
      <SettingsModal show={showSettingsModal} setShow={setShowSettingsModal} />
      <SubscriptionModal
        showModal={showBuySubscriptionModal}
        setShowModal={setShowBuySubscriptionModal}
      />

      <ChangeAppearanceModal
        show={showAppearanceModal}
        setShow={setShowAppearanceModal}
      />

      <WelcomeModal 
        showModal={showWelcomeModal} 
        setShowModal={setShowWelcomeModal}
      />

      <TopBar />
      <EmptySpaceForTopBar />
      <Outlet />
    </MainAppContainer>
  );
};

const EmptySpaceForTopBar = styled.div`
  height: 60px;
`;

const TIME_TO_CLOSE_TOAST = 2000;
// Default toast styles, "save folder" toast does overwrite this though
const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 8px;
    background: ${LIGHTER_BG_FOR_TOAST};
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.9);
    color: ${PRIMARY_TEXT_COLOR};
    padding: 8px;
  }

  .Toastify__toast.SaveToast {
    background: transparent;
    box-shadow: none;
  }
`;

const MainAppContainer = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 98vw;
  height: ${(props) => props.height}px;

  // This causes the animation when the app is first loaded
  animation: ${fadeIn} 2s ease-in-out;
`;

export default MainApp;
